import React, { useEffect, useRef, useState, useMemo } from "react"
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import { macroStyles } from "../../Styles/Macro";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "./Buttons";
import { toast } from "react-toastify";
import Api from "../../helper/api";
import { formatTime, formatedDate, formatedDateInDigit } from "./../../helper/helper";
import { useReactToPrint } from "react-to-print";

import CustomImage from "./CustomImage";
import { setValues } from "../../redux/findPatientSlice";
import { useDispatch, useSelector } from "react-redux";
import JoditEditor, { Jodit } from "jodit-react";
import "jodit";
import GetMacro from "./GetMacro";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  height: "100%",
  width: "800.88px",
  padding: "0 0px",
  overflow: "scroll",
  display: "block",

  borderRadius: "6px",
  "&:focus": {
    outline: "none",
  },
};

const copyStringToClipboard = function (str) {
  var el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const facilityMergeFields = [
  "FacilityNumber",
  "FacilityName",
  "Address",
  "MapCategory",
  "Latitude",
  "Longitude",
  "ReceivingPlant",
  "TrunkLine",
  "SiteElevation"
];
const inspectionMergeFields = [
  "InspectionCompleteDate",
  "InspectionEventType"
];

const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement("optgroup");
  optionGroupElement.setAttribute("label", optionGrouplabel);
  for (let index = 0; index < mergeFields.length; index++) {
    let optionElement = document.createElement("option");
    optionElement.setAttribute("class", "merge-field-select-option");
    optionElement.setAttribute("value", mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
}

const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  "|",
  "ul",
  "ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  "image",
  "link",
  "table",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|",
  "source",
  "|",
  {
    name: "insertMergeField",
    tooltip: "Insert Merge Field",
    iconURL: "images/merge.png",
    popup: (editor, current, self, close) => {
      function onSelected(e) {
        let mergeField = e.target.value;
        if (mergeField) {
          console.log(mergeField);
          editor.selection.insertNode(
            editor.create.inside.fromHTML("{{" + mergeField + "}}")
          );
        }
      }
      let divElement = editor.create.div("merge-field-popup");

      let labelElement = document.createElement("label");
      labelElement.setAttribute("class", "merge-field-label");
      labelElement.text = 'Merge field: ';
      divElement.appendChild(labelElement);

      let selectElement = document.createElement("select");
      selectElement.setAttribute("class", "merge-field-select");
      selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
      selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
      selectElement.onchange = onSelected;
      divElement.appendChild(selectElement);

      console.log(divElement);
      return divElement;
    }
  },
  {
    name: "copyContent",
    tooltip: "Copy HTML to Clipboard",
    iconURL: "images/copy.png",
    exec: function (editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    }
  }
];

const AddMacro = ({ handleClose, macroId, id }) => {
  const api = new Api();
  const classes = formStyles();
  const macroclasses = macroStyles();
  const [preview, setPreview] = useState(false);
  const [value, setValue] = useState("");

  const dispatch = useDispatch()
  const { reFetch } = useSelector(state => state.findPatient)


  const [open, setOpen] = React.useState(true);
  const macros = useRef(null)
  const [patience, setPatience] = useState();
  const [report, setReport] = useState();
  const [test, setTest] = useState([]);
  const reportContent = useRef();
  const [macroAdd, setMacroAdd] = useState(false)
  const token = localStorage.getItem("logintoken")


  const fetchData = async () => {
    const { data, status } = await api.fetchPatientById({}, { id: id })
    setPatience(data);

    const investigation = data.investigation.find((item) => item._id === parseInt(macroId))
    setReport(investigation)
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchTest = async () => {
      try {

        for (let i = 0; i < report.reportFormat.length; i++) {
          const id = report?.reportFormat[i]

          const { data, status } = await api.fetchReportById({}, { id });

          if (status === 200) {
            if (data.report?.reportsubcategory.length > 0) {
              for (let i = 0; i < data.report?.reportsubcategory.length; i++) {
                const testId = data.report.reportsubcategory[i]._id;

                const testExist = test.find((test) => test._id === testId)

                if (!testExist) {
                  setTest((prev) => {
                    return [...prev, data.report.reportsubcategory[i]]
                  })
                }
              }
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    }

    if (report?.reportFormat.length > 0) {
      fetchTest()
    }

  }, [report?._id])



  const fetchTest = async () => {
    try {
      const { data, status } = await api.fetchTestById({}, {
        patient: id,
        investigationId: macroId
      })

      if (data.data?.macroHtml) {

        setValue(data.data.macroHtml)
      }

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (macroId) {
      fetchTest()
    }
  }, [macroId])

  const editorConfig = useMemo(() => {
    return {
      readonly: false,
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      //defaultActionOnPaste: "insert_clear_html",
      buttons: buttons,
      uploader: {
        insertImageAsBase64URI: false,
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
        withCredentials: false,
        format: 'json',
        method: 'POST',
        url: 'https://backend.nagalikardiagnostic.com/api/upload-file',
        headers: {
          "authtoken": token
        },
        prepareData: function (data) {
          return data;
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMsg: function (resp) {
          return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
        },
        process: function (resp) {
          return {
            files: [resp[0].src],
            path: '',
            baseurl: '',
            error: resp.error ? 1 : 0,
            msg: resp.msg
          };
        },
        defaultHandlerSuccess: function (data, resp) {
          const files = data.files || [];
          if (files.length) {
            this.selection.insertImage(files[0], null, 250);
          }
        },
        defaultHandlerError: function (resp) {
          this.events.fire('errorPopap', this.i18n(resp.msg));
        }
      },
      width: '100%',
      height: 842
    };
  }, [])

  const handleProcedureContentChange = (content) => {
    setValue(content)
  };


  const saveTemplate = async () => {
    try {
      const { data, status } = await api.updateMacro({
        investigationId: macroId,
        patient: id,
        macroHtml: value
      })

      if (status === 200) {
        toast.success("Saved Successfully")
        dispatch(setValues({ name: "reFetch", value: !reFetch }))
        handleClose()
      }
    } catch (err) {
      toast.error("Error Saving!")
    }
  }

  const insertMacro = (macro) => {
    setValue((value) => value + macro)
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="overflow-y-scroll px-0"
    >
      <Box sx={style} >
        <div >
          <header className="flex justify-between  px-6 py-6 bg-[#FAFAFA]">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={macroclasses.name}
            >
              Macros Template
            </Typography>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </header>

          {
            preview
              ? <div className="flex gap-4 border-b border-t border-[rgba(201, 201, 201, 1)] py-4 px-[50px]
            text-[14px] font-[Poppins]">
                <button
                  onClick={() => setPreview(false)}
                  className={`bg-white px-4 h-[32px] text-[#273142] font-medium 
        text-center rounded-[43px] border border-[#273142]`}
                >
                  Edit
                </button>
                <button
                  onClick={() => setPreview(true)}
                  className={`bg-[#273142] text-center px-4 text-white font-medium rounded-[43px]`}
                >
                  Preview
                </button>
              </div>
              : <div className="flex gap-4 border-b border-t border-[rgba(201, 201, 201, 1)] py-4 px-[50px]
            text-[14px] font-[Poppins]">
                <button
                  onClick={() => setPreview(false)}
                  className={`bg-[#273142] text-center px-4 text-white font-medium rounded-[43px]`}
                >
                  Edit
                </button>
                <button
                  onClick={() => setPreview(true)}
                  className={`bg-white px-4 h-[32px] text-[#273142] font-medium 
          text-center rounded-[43px] border border-[#273142]`}
                >
                  Preview
                </button>
              </div>
          }
          {
            !preview
              ?
              <>
                {
                  macroAdd &&
                  <GetMacro setTest={setMacroAdd} insertMacro={insertMacro} />
                }
                <button onClick={() => setMacroAdd(true)} style={{ background: "#B82C3A", color: "#fff", padding: 5, borderRadius: 2, marginLeft: 10 }}>Add macro</button>
                <div style={{ display: "grid", border: 'none !important', }} className="reportLayout">

                  <JoditEditor
                    ref={macros}
                    value={value}
                    config={editorConfig}
                    onChange={value => handleProcedureContentChange(value)}
                  />
                </div>
              </>
              : <Box>
                <div className="jodit-editor no-border">
                  <div className="jodit-wysiwyg">
                    <main className=" px-6 py-6 ">
                      <div className="flex items-center px-6">
                        <div className="bg-[#B82C3A] w-fit p-3 text-white font-semibold">
                          LOGO
                        </div>
                        <div className="flex-1 grid justify-center">
                          <h1 className="text-[#B82C3A] text-lg font-medium">
                            Naglikar diagnostic labouratory
                          </h1>
                          <p className="text-xs text-[#B5B5C3]">
                            Nariguddenahalli, kuvempu road, Bangalore -560064
                          </p>
                        </div>
                      </div>
                      <div className="bg-[#B82C3A] h-1 w-full my-6"></div>
                      <ul className="flex justify-between max-w-2xl mx-auto text-[#464E5F] font-medium">
                        <div className="flex-1" style={{ color: "#000" }}>
                          <li
                            className="my-0 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Sample No</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1" style={{ fontWeight: 600 }}>{patience._id}</div>
                          </li>
                          <li
                            className="my-0 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Patient name</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{patience?.firstName} {patience?.lastName}</div>
                          </li>

                          <li
                            className="my-0 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Reffered by</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{patience?.refferedBy?.name}</div>
                          </li>
                        </div>
                        <div className="flex-1" style={{ color: "#000" }}>
                          <li
                            className="my-0 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Bill No</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{`${patience?._id}${report?._id}`}</div>
                          </li>
                          <li
                            className="my-0 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Age/Sex</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{`${patience?.age}yrs`} / {patience?.gender}</div>
                          </li>
                          <li
                            className="my-0 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Date</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{formatedDateInDigit(new Date())}</div>
                          </li>
                        </div>
                      </ul>
                      <div>
                        <h1 className=" font-semibold my-10 text-center text-[rgb(70,78,95)] underline underline-offset-2">
                          {/* {report?.map((repo) => repo?.reportcategory?.name)}{" "} */}
                        </h1>
                        {
                          patience?.patients?.testInfo?.map((subCategores) => {
                            return <>
                              {
                                subCategores?.subcategories?.map((subcat) => {
                                  console.log("patience subcat", subcat);
                                  return (
                                    <>
                                      <div className="grid grid-cols-[1fr_100px_100px_1fr]  my-2  font-semibold text-sm max-w-[650px] mx-auto ">
                                        <div>{subcat?.reportFormat?.map((repItem) => {
                                          return <span>{repItem?.reportName}</span>
                                        })}</div>

                                      </div>

                                      <div className="grid grid-cols-[1fr_100px_100px_1fr] text-[#464E5F]/90  my-1  text-sm max-w-[650px] mx-auto">
                                        <div>{subcat?.name}</div>
                                        <div>{subcat?.patienceresult}</div>
                                        <div>{subcat?.units}</div>
                                        <div className="text-center">
                                          {subcat?.rangeForMale?.map((ran) => {
                                            return (
                                              <>
                                                <p>
                                                  High: {ran.high}-
                                                  {subcat?.units}
                                                </p>
                                                <p>
                                                  Low: {ran.low}-
                                                  {subcat?.units}
                                                </p>
                                              </>
                                            );
                                          })}
                                        </div>
                                      </div>

                                    </>
                                  );
                                })}
                            </>
                          })
                        }

                      </div>

                      {
                        value &&
                        <div dangerouslySetInnerHTML={{ __html: value }}>
                        </div>
                      }

                      <div className="mt-10 px-20">
                        <div className="flex flex-col items-end">
                          {
                            report?.sign &&
                            <img src={report?.sign} style={{ width: 95, height: 75, objectFit: "contain" }} />
                          }
                        </div>
                      </div>

                      <div className="text-center text-sm ">
                        <h1 className="font-medium text-center" style={{ lineHeight: "34px" }}>-----End of report-----</h1>
                      </div>
                    </main>
                  </div>
                </div>
              </Box>
          }
        </div>
        {
          !preview &&
          <div
            style={{
              marginTop: '70px',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'right',
              marginRight: '4%',
              marginBottom: "20px"
            }}>
            <Buttons className={classes.cancelButton} onClick={handleClose}>
              Cancel
            </Buttons>
            <Buttons
              className={classes.submitButton}
              onClick={saveTemplate}
            >
              Save
            </Buttons>
          </div>
        }
      </Box>
    </Modal>
  );

}

export default AddMacro;