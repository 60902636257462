import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { API } from "../../config";
import { formatTime, formatedDate, formatedDateInDigit } from "./../../helper/helper";
import { useReactToPrint } from "react-to-print";
import Api from "../../helper/api";
import RenderRange from "./RenderRange";
import "jodit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  width: "800.88px",
  padding: "0 10px",
  height: "100%",
  overflow: "scroll",
  height: "100%",
  display: "block",

  borderRadius: "6px",
  "&:focus": {
    outline: "none",
  },
};

const ReportPreview = () => {
  const [open, setOpen] = React.useState(true);
  const api = new Api();
  const params = useParams();
  const [patience, setPatience] = useState();
  const [report, setReport] = useState();
  const [test, setTest] = useState([]);
  const [macro, setMacro] = useState(null);

  const handleOpen = () => setOpen(true);
  const navigate = useNavigate();
  const reportContent = useRef();


  const fetchData = async () => {
    const { data, status } = await api.fetchPatientById({}, { id: params.id })
    setPatience(data);

    const investigation = data?.investigation?.find((item) => item._id === parseInt(params.reportId))
    const macro = data?.testResult?.find((item) => item.investigationId === parseInt(params.reportId))
    setReport(investigation)

    if (macro) {
      setMacro(macro)
    }
  };

  useEffect(() => {
    fetchData();
  }, [params.id]);

  useEffect(() => {
    const fetchTest = async () => {
      try {
        let test = [];
        let testInfo = {}
        const isMale = patience?.gender === "male" ? true : false
        const age = patience?.age;

        for (let i = 0; i < report?.reportFormat?.length; i++) {
          const id = report?.reportFormat[i]

          const { data, status } = await api.fetchReportById({}, { id });

          if (status === 200) {
            if (data.report?.reportsubcategory?.length > 0) {
              for (let i = 0; i < data?.report?.reportsubcategory?.length; i++) {
                const testId = data?.report?.reportsubcategory[i]?._id;

                const testExist = test.find((test) => test._id === testId)

                if (!testExist) {

                  test = [
                    ...test,
                    {
                      ...data?.report?.reportsubcategory[i],
                      reportName: data?.report?.reportName,
                      reportId: data?.report?._id
                    }
                  ]
                }
              }
            }
          }

          let testClone = test.reduce((acc, curr) => {
            if (curr.id) {
              acc[curr.id] = curr
            }

            return acc
          }, {})

          testInfo = testClone

          if (data.report?.layout) {
            const isLow = (key, data, isMale, age) => {
              const test = testInfo[key]

              if (!data) {
                return false
              }

              if (!test) {
                return false
              }

              if (isMale) {
                const range = test.rangeForMale
                let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                let ran = {};

                for (let i = 0; i < rangeClone.length; i++) {
                  let curr = rangeClone[i].ageUpto

                  if (Object.keys(ran).length === 0) {
                    if (curr >= age) {
                      ran = rangeClone[i];
                    }
                  }
                }

                if (ran?.low ?? false) {
                  return false
                }

                if (data >= ran.low) {
                  return false
                } else {
                  return true
                }
              } else {
                const range = test.rangeForFemale
                let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                let ran = {}

                for (let i = 0; i < rangeClone.length; i++) {
                  let curr = rangeClone[i].ageUpto
                  if (Object.keys(ran).length === 0) {
                    if (curr >= age) {
                      ran = rangeClone[i];
                    }
                  }
                }

                if (ran?.low ?? false) {
                  return false
                }

                if (data >= ran.low) {
                  return false
                } else {
                  return true
                }
              }
            }

            const isHigh = (key, data, isMale, age) => {
              const test = testInfo[key]

              if (!data) {
                return false
              }

              if (!test) {
                return false
              }

              if (isMale) {
                const range = test.rangeForMale
                let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                let ran = {}

                for (let i = 0; i < rangeClone.length; i++) {
                  let curr = rangeClone[i].ageUpto
                  if (Object.keys(ran).length === 0) {
                    if (curr >= age) {
                      ran = rangeClone[i];
                    }
                  }
                }

                if (ran?.high ?? false) {
                  return false
                }

                if (data <= ran.high) {
                  return false
                } else {
                  return true
                }

              } else {
                const range = test.rangeForFemale
                let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                let ran = {}

                for (let i = 0; i < rangeClone.length; i++) {
                  let curr = rangeClone[i].ageUpto
                  if (Object.keys(ran).length === 0) {
                    if (curr >= age) {
                      ran = rangeClone[i];
                    }
                  }
                }

                if (ran?.high ?? false) {
                  return false
                }

                if (data <= ran.high) {
                  return false
                } else {
                  return true
                }
              }
            }

            const replaceResult = (text, data) => {
              if (!data) return text; // Return original text if data is not available
              return text.replace(/{(.*?)}/g, (_, key) => {
                const _id = testInfo[key]._id
                const value = data.find((data) => data.testId === _id)?.result || ""
                const low = isLow(key, value, isMale, age)
                const high = isHigh(key, value, isMale, age)

                if (low || high) {
                  return `
                  <em>
                      <strong>
                          <u>${value}</u>
                      </strong>
                  </em>
                  `
                } else {
                  return value
                }
              });
            };

            const replaceLowRange = (text, data, isMale, age) => {
              if (!data) return text; // Return original text if data is not available
              return text.replace(/\|(\w+)\|/g, (_, key) => {
                const test = testInfo[key]
                if (!test) {
                  return text
                }

                if (isMale) {
                  const range = test.rangeForMale
                  let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                  let ran = {}

                  for (let i = 0; i < rangeClone.length; i++) {
                    let curr = rangeClone[i].ageUpto
                    if (Object.keys(ran).length === 0) {
                      if (curr >= age) {
                        ran = rangeClone[i];
                      }
                    }
                  }

                  return ran?.low ?? ""
                } else {
                  const range = test.rangeForFemale
                  let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                  let ran = {}

                  for (let i = 0; i < rangeClone.length; i++) {
                    let curr = rangeClone[i].ageUpto
                    if (Object.keys(ran).length === 0) {
                      if (curr >= age) {
                        ran = rangeClone[i];
                      }
                    }
                  }

                  return ran?.low ?? ""
                }
              });
            }

            const replaceHighRange = (text, data, isMale, age) => {
              if (!data) return text; // Return original text if data is not available
              return text.replace(/\$(\w+)\$/g, (_, key) => {
                const test = testInfo[key]
                if (!test) {
                  return text
                }

                if (isMale) {
                  const range = test.rangeForMale
                  let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                  let ran = {}

                  for (let i = 0; i < rangeClone.length; i++) {
                    let curr = rangeClone[i].ageUpto
                    if (Object.keys(ran).length === 0) {
                      if (curr >= age) {
                        ran = rangeClone[i];
                      }
                    }
                  }

                  return ran?.high ?? ""
                } else {
                  const range = test.rangeForFemale
                  let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                  let ran = {}

                  for (let i = 0; i < rangeClone.length; i++) {
                    let curr = rangeClone[i].ageUpto
                    if (Object.keys(ran).length === 0) {
                      if (curr >= age) {
                        ran = rangeClone[i];
                      }
                    }
                  }

                  return ran?.high ?? ""
                }
              });
            }

            let layout = replaceResult(data.report.layout, macro.testResult)
            layout = replaceLowRange(layout, macro.testResult, isMale, age)
            layout = replaceHighRange(layout, macro.testResult, isMale, age)



            setTest((prev) => {
              return { ...prev, [id]: { layout } }
            })
          }
        }

      } catch (err) {
        console.log(err)
      }
    }

    if (report?.reportFormat?.length > 0 && macro) {
      fetchTest()
    }
  }, [report?._id, macro])

  const handleClose = () => {
    setOpen(false);
    navigate(`/list-patient`);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="overflow-y-scroll "
    >
      <Box sx={style} className="">
        <div >
          <header className="flex justify-between border-b border-[#C9C9C9] px-6 py-6 ">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Report preview
            </Typography>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </header>
          <main className=" px-6 py-6 " id="report-content" ref={reportContent}>
            <div className="flex items-center px-6">
              <div className="bg-[#B82C3A] w-fit p-3 text-white font-semibold">
                LOGO
              </div>
              <div className="flex-1 grid justify-center">
                <h1 className="text-[#B82C3A] text-lg font-medium">
                  Naglikar diagnostic labouratory
                </h1>
                <p className="text-xs text-[#B5B5C3]">
                  Nariguddenahalli, kuvempu road, Bangalore -560064
                </p>
              </div>
            </div>
            <div className="bg-[#B82C3A] h-1 w-full my-6"></div>
            <ul className="flex justify-between max-w-2xl mx-auto text-[#464E5F] font-medium">
              <div className="flex-1">
                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Sample No</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{patience?.sampleNo}</div>
                </li>
                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Patient name</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{patience?.firstName} {patience?.lastName}</div>
                </li>
                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Reffered by</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{patience?.refferedBy?.name}</div>
                </li>

              </div>
              <div className="flex-1">
                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Bill No</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{`${patience?._id}${report?._id}`}</div>
                </li>
                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Age/Sex</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{`${patience?.age}yrs`} / {patience?.gender}</div>
                </li>

                <li
                  className="my-0 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Date</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{formatedDateInDigit(new Date())}</div>
                </li>
              </div>
            </ul>

            {
              macro?.layout ? <div className="ql-editor">
                <div className="reportLayout">
                  <div dangerouslySetInnerHTML={{ __html: macro?.layout }}>
                  </div>
                </div>
              </div>
                :
                <>
                  <div className="jodit-editor no-border">
                    {
                      Object.keys(test).map((id) => {
                        return <>
                          <div className="jodit-wysiwyg">
                            <div dangerouslySetInnerHTML={{ __html: test[id]?.layout }}>
                            </div>
                          </div>
                        </>
                      })
                    }
                  </div>
                </>
            }

            {
              macro?.macroHtml &&
              <div className="ql-editor">
                <div dangerouslySetInnerHTML={{ __html: macro?.macroHtml }}>
                </div>
              </div>
            }

            <div className="mt-10 px-20">
              <div className="flex flex-col items-end">
                {
                  report?.sign &&
                  <img src={report?.sign} style={{ width: 95, height: 75, objectFit: "contain" }} />
                }
              </div>
            </div>
            <div className="text-center text-sm ">
              <h1 className="font-medium text-center">-----End of report-----</h1>
            </div>
            <div className="text-center flex justify-between align-middle">
              <h1 className="font-medium text-center" style={{ lineHeight: "34px" }}>Entered by: {macro?.enteredBy?.replace(/^\w/, c => c.toUpperCase())}</h1>
              <h1 className="font-medium text-center" style={{ lineHeight: "34px" }}>Verified by: {macro?.verifiedBy?.replace(/^\w/, c => c.toUpperCase())}</h1>
            </div>
          </main>
        </div>
      </Box>
    </Modal>
  );
};

export default ReportPreview;
