import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import { useReactToPrint } from "react-to-print";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useFormik } from "formik";
import * as Yup from "yup";
import Api from "../../helper/api";
import Select, { components, } from "react-select"
import "jodit";
import MasterMacro from "../../Components/Shared/MasterMacro";
import DeleteMacro from "../Accounts/DeleteMacro";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

const Macro = () => {
    const tableclasses = tableStyles();
    const api = new Api();
    const TOKEN = localStorage.getItem("logintoken");
    const navigate = useNavigate();
    const [values, setValues] = useState([]);
    const [group, setGroup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [activeMacro, setActiveMacro] = useState({
        _id: "",
        "code": ""
    });
    // const []
    const [action, setAction] = useState({
        addNew: false,
        edit: false,
        delete: false
    })

    const macroContent = useRef();

    useEffect(() => {
        fetchGroup();
    }, []);

    useEffect(() => {
        fetchData();
    }, [selectedGroup, refresh]);

    const fetchData = async () => {
        try {
            const { data, status } = await api.macro().get(`/macro?group=${selectedGroup}`)

            if (status === 200) {
                setValues(data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    // console.log("values-------", values)
    const fetchGroup = async () => {
        try {
            const { data, status } = await api.fetchReportGroup();

            if (status === 200) {
                setGroup(data.testCategory)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const generatePDF = useReactToPrint({
        content: () => macroContent.current,
        documentTitle: "PatientReport",
    });

    const handleMacroKeyDown = (event) => {
        const findIndex = values.findIndex(row => row._id === activeMacro?._id)
        if (event.keyCode === 38) { // Up arrow key
            const macro = values[findIndex - 1];
            if (macro) {
                setActiveMacro(macro);
            }
        } else if (event.keyCode === 40) { // Down arrow key
            const macro = values[findIndex + 1];
            if (macro) {
                setActiveMacro(macro)
            }
        }
    };

    const handleEdit = () => {
        if (!activeMacro._id) {
            toast.error("Please select the macro first")
            return null
        }

        setAction((prev) => {
            return {
                ...prev, edit: true
            }
        })

    }

    const handleDelete = () => {
        if (!activeMacro._id) {
            toast.error("Please select the macro first")
            return null
        }

        setAction((prev) => {
            return {
                ...prev, delete: true
            }
        })
    }

    return (
        <div className={tableclasses.root}>

            <div className={tableclasses.body}>
                <div className={tableclasses.header}>
                    <div className={tableclasses.name}>
                        <div className={tableclasses.h2}>Macro </div>
                    </div>

                    <div>
                        <Buttons className={tableclasses.printButton} onClick={() => setAction((prev) => { return { ...prev, addNew: true } })}>
                            Add
                        </Buttons>
                        <Buttons className={tableclasses.printButton} onClick={handleEdit}>
                            Edit
                        </Buttons>
                        <Buttons className={tableclasses.printButton} onClick={handleDelete}>
                            Delete
                        </Buttons>
                        <Buttons className={tableclasses.printButton} onClick={generatePDF}>
                            Print
                        </Buttons>
                    </div>
                </div>

                <div style={{ width: "94%", minHeight: "100vh", display: "flex", marginBottom: 8, gap: 15 }}>
                    <div style={{ width: "220px", display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "center", gap: 5 }}>
                            <h5 style={{ marginTop: 6 }}>Group:</h5>
                            <Select
                                options={[{
                                    options: [
                                        {
                                            value: "",
                                            label: "All"
                                        },
                                        ...group?.map((group) => {
                                            return {
                                                value: group._id,
                                                label: group.name
                                            }
                                        })
                                    ]
                                }]}
                                onChange={(value) => setSelectedGroup(value.value)}
                                value={{
                                    value: group.find((group) => group._id === selectedGroup)?._id || "",
                                    label: group.find((group) => group._id === selectedGroup)?.name || "All"
                                }}
                                styles={{ container: (styles) => ({ ...styles, marginTop: '0px', marginBottom: "10px", width: "100%", fontSize: "12px" }), input: (styles) => ({ ...styles, fontSize: "10px" }), control: (styles) => ({ ...styles, padding: "0px 0" }) }}
                            />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ border: "1px solid hsl(0, 0%, 80%)", borderRadius: 4 }} onKeyDown={handleMacroKeyDown} tabIndex="0">
                                <h5 style={{ borderBottom: "1px solid hsl(0, 0%, 80%)", textAlign: "center" }}>Macro Code</h5>
                                <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
                                    {
                                        values.map((value) => (
                                            <div onClick={() => setActiveMacro(value)} style={{ borderBottom: "1px solid hsl(0, 0%, 80%)", fontSize: 12, fontWeight: 500, padding: "0px 3px", background: value._id === activeMacro?._id ? "rgb(44 153 184 / 35%)" : "transparent" }}>
                                                {
                                                    value.code
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={macroContent} style={{ border: "1px solid hsl(0, 0%, 80%)", width: "100%", padding: "15px" }}>
                        <div className="jodit-editor no-border">
                            <div className="jodit-wysiwyg">
                                {
                                    activeMacro?.macro &&
                                    <div dangerouslySetInnerHTML={{ __html: activeMacro?.macro }}>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddNewMac
                action={action}
                group={group}
                setAction={setAction}
                setActiveMacro={setActiveMacro}
                refresh={() => setRefresh(!refresh)}
                handleClose={() => setAction((prev) => { return { ...prev, addNew: false } })}
            />
            {
                action.delete &&
                <DeleteMacro
                    handleClose={() => setAction((prev) => { return { ...prev, delete: false } })}
                    data={activeMacro}
                    refresh={() => setRefresh(!refresh)}
                />
            }
            
            {
                action.edit &&
                <MasterMacro
                    handleClose={() => setAction((prev) => { return { ...prev, edit: false } })}
                    data={activeMacro}
                    refresh={() => setRefresh(!refresh)}
                />
            }
        </div>
    );
};

const AddNewMac = ({ action, handleClose, group, setAction, setActiveMacro, refresh }) => {
    const api = new Api();
    const tableclasses = tableStyles();
    const schema = Yup.object({
        code: Yup.string().required("Code is required."),
        group: Yup.string().required("Group is required")
    })
    const [loading, setLoading] = useState(false);

    const { values, handleChange, setFieldValue } = useFormik({
        initialValues: {
            code: "",
            group: ""
        },
        validationSchema: schema,
    })

    const handleSave = async () => {
        try {
            setLoading(true)
            if (!values.code || !values.group) {
                toast.error("All fields are required")
                setLoading(false)
                return null
            }

            const { data, status } = await api.macro().post("/macro", {
                ...values
            })

            if (status === 200) {
                toast.success("Saved successfully.")
                setLoading(false)
                refresh();
                setActiveMacro(data)
                setAction((prev) => {
                    return {
                        ...prev, edit: true
                    }
                })
                handleClose();
            }

        } catch (err) {
            setLoading(false);
        }
    }
    return (
        <Modal
            open={action.addNew}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {/* <h5 style={{ fontWeight: 500 }}>Add</h5> */}

                <div>
                    <h6 style={{ fontWeight: 600 }}>
                        Code:
                    </h6>
                    <input type="text" name="code" value={values.code} onChange={handleChange} style={{ border: "1px solid hsl(0, 0%, 80%)", outline: 'none', width: "100%", padding: 9, borderRadius: 4 }} />
                </div>

                <div style={{ marginTop: 10 }}>
                    <h6 style={{ fontWeight: 600 }}>
                        Group:
                    </h6>
                    <Select
                        options={[{
                            label: "Group",
                            options: group?.map((group) => {
                                return {
                                    value: group._id,
                                    label: group.name
                                }
                            })
                        }]}
                        onChange={(value) => setFieldValue("group", value.value)}
                        value={{
                            value: group.find((group) => group._id === values.group)?._id,
                            label: group.find((group) => group._id === values.group)?.name
                        }}
                        styles={{ container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }), control: (styles) => ({ ...styles, padding: "3px 0" }) }}
                    />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Buttons className={tableclasses.printButton} disabled={loading} style={{ width: "120px" }} onClick={handleSave}>
                        {
                            loading ? "Saving.." : "Save"
                        }
                    </Buttons>
                    <Buttons className={tableclasses.printButton} style={{ width: "120px", background: "#fff", color: "#000" }} onClick={handleClose}>
                        Cancel
                    </Buttons>
                </div>
            </Box>
        </Modal>
    )
}



export default Macro;
