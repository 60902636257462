import React, { useEffect, useMemo, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import { macroStyles } from "../../Styles/Macro";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "./Buttons";
import { toast } from "react-toastify";
import Api from "../../helper/api";
import { formatTime, formatedDate, formatedDateInDigit } from "./../../helper/helper";
import { useReactToPrint } from "react-to-print";
import { stringify } from 'flatted'
import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';
import CustomImage from "./CustomImage";
import { useDispatch, useSelector } from "react-redux";
import { setValues } from "../../redux/findPatientSlice";
import JoditEditor, { Jodit } from "jodit-react";
import "jodit";
import GetMacro from "./GetMacro";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  height: "100%",
  width: "800.88px",
  padding: "0 0px",
  overflow: "scroll",
  display: "block",

  borderRadius: "6px",
  "&:focus": {
    outline: "none",
  },
};

const copyStringToClipboard = function (str) {
  var el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const facilityMergeFields = [
  "FacilityNumber",
  "FacilityName",
  "Address",
  "MapCategory",
  "Latitude",
  "Longitude",
  "ReceivingPlant",
  "TrunkLine",
  "SiteElevation"
];
const inspectionMergeFields = [
  "InspectionCompleteDate",
  "InspectionEventType"
];

const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement("optgroup");
  optionGroupElement.setAttribute("label", optionGrouplabel);
  for (let index = 0; index < mergeFields.length; index++) {
    let optionElement = document.createElement("option");
    optionElement.setAttribute("class", "merge-field-select-option");
    optionElement.setAttribute("value", mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
}

const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  "|",
  "ul",
  "ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  "image",
  "link",
  "table",
  "|",
  "hr",
  "eraser",
  "copyformat",
  "|",
  "fullsize",
  "selectall",
  "print",
  "|",
  "source",
  "|",
  {
    name: "insertMergeField",
    tooltip: "Insert Merge Field",
    iconURL: "images/merge.png",
    popup: (editor, current, self, close) => {
      function onSelected(e) {
        let mergeField = e.target.value;
        if (mergeField) {
          console.log(mergeField);
          editor.selection.insertNode(
            editor.create.inside.fromHTML("{{" + mergeField + "}}")
          );
        }
      }
      let divElement = editor.create.div("merge-field-popup");

      let labelElement = document.createElement("label");
      labelElement.setAttribute("class", "merge-field-label");
      labelElement.text = 'Merge field: ';
      divElement.appendChild(labelElement);

      let selectElement = document.createElement("select");
      selectElement.setAttribute("class", "merge-field-select");
      selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
      selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
      selectElement.onchange = onSelected;
      divElement.appendChild(selectElement);

      console.log(divElement);
      return divElement;
    }
  },
  {
    name: "copyContent",
    tooltip: "Copy HTML to Clipboard",
    iconURL: "images/copy.png",
    exec: function (editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    }
  }
];

const ReportLayout = ({ handleClose, layoutId, id }) => {
  const api = new Api();
  const classes = formStyles();
  const macroclasses = macroStyles();
  const dispatch = useDispatch()
  const { reFetch } = useSelector(state => state.findPatient)

  const [open, setOpen] = React.useState(true);
  const macros = useRef(null)
  const [patience, setPatience] = useState();
  const [report, setReport] = useState();
  const [macro, setMacro] = useState(null);
  const [test, setTest] = useState([]);
  const [value, setValue] = useState()
  const token = localStorage.getItem("logintoken")
  const [macroAdd, setMacroAdd] = useState(false)

  const fetchData = async () => {
    const { data, status } = await api.fetchPatientById({}, { id: id })
    setPatience(data);

    const investigation = data.investigation.find((item) => item._id === parseInt(layoutId))
    const macro = data?.testResult.find((item) => item.investigationId === parseInt(layoutId))
    setReport(investigation)

    if (macro) {
      setMacro(macro)
      if (macro.layout) {
        setValue(macro?.layout)
      }

    } else {
      setMacro(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchTest = async () => {
      try {
        setTest([]);
        let test = [];
        let testInfo = {};
        const isMale = patience?.gender === "male" ? true : false;
        const age = patience?.age;

        for (let i = 0; i < report.reportFormat.length; i++) {
          const id = report?.reportFormat[i]

          const { data, status } = await api.fetchReportById({}, { id });

          if (status === 200) {
            if (data.report?.reportsubcategory.length > 0) {
              for (let i = 0; i < data.report?.reportsubcategory.length; i++) {
                const testId = data.report.reportsubcategory[i]._id;

                const testExist = test.find((test) => test._id === testId)

                if (!testExist) {

                  test = [
                    ...test,
                    {
                      ...data.report.reportsubcategory[i],
                      reportName: data.report?.reportName,
                      reportId: data.report?._id
                    }
                  ]
                }

                let testClone = test.reduce((acc, curr) => {
                  if (curr.id) {
                    acc[curr.id] = curr
                  }

                  return acc
                }, {})

                testInfo = testClone;

                if (data.report?.layout) {

                  const isLow = (key, data, isMale, age) => {
                    const test = testInfo[key]

                    if (!data) {
                      return false
                    }

                    if (!test) {
                      return false
                    }

                    if (isMale) {
                      const range = test.rangeForMale
                      let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                      let ran = {};

                      for (let i = 0; i < rangeClone.length; i++) {
                        let curr = rangeClone[i].ageUpto

                        if (Object.keys(ran).length === 0) {
                          if (curr >= age) {
                            ran = rangeClone[i];
                          }
                        }
                      }

                      if (ran?.low ?? false) {
                        return false
                      }

                      if (data >= ran.low) {
                        return false
                      } else {
                        return true
                      }
                    } else {
                      const range = test.rangeForFemale
                      let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                      let ran = {}

                      for (let i = 0; i < rangeClone.length; i++) {
                        let curr = rangeClone[i].ageUpto
                        if (Object.keys(ran).length === 0) {
                          if (curr >= age) {
                            ran = rangeClone[i];
                          }
                        }
                      }

                      if (ran?.low ?? false) {
                        return false
                      }

                      if (data >= ran.low) {
                        return false
                      } else {
                        return true
                      }
                    }
                  }

                  const isHigh = (key, data, isMale, age) => {
                    const test = testInfo[key]

                    if (!data) {
                      return false
                    }

                    if (!test) {
                      return false
                    }

                    if (isMale) {
                      const range = test.rangeForMale
                      let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                      let ran = {}

                      for (let i = 0; i < rangeClone.length; i++) {
                        let curr = rangeClone[i].ageUpto
                        if (Object.keys(ran).length === 0) {
                          if (curr >= age) {
                            ran = rangeClone[i];
                          }
                        }
                      }

                      if (ran?.high ?? false) {
                        return false
                      }

                      if (data <= ran.high) {
                        return false
                      } else {
                        return true
                      }

                    } else {
                      const range = test.rangeForFemale
                      let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                      let ran = {}

                      for (let i = 0; i < rangeClone.length; i++) {
                        let curr = rangeClone[i].ageUpto
                        if (Object.keys(ran).length === 0) {
                          if (curr >= age) {
                            ran = rangeClone[i];
                          }
                        }
                      }

                      if (ran?.high ?? false) {
                        return false
                      }

                      if (data <= ran.high) {
                        return false
                      } else {
                        return true
                      }
                    }
                  }

                  const replaceResult = (text, data) => {
                    if (!data) return text; // Return original text if data is not available
                    return text.replace(/{(.*?)}/g, (_, key) => {
                      const _id = testInfo[key]?._id
                      const value = data.find((data) => data.testId === _id)?.result || ""

                      const low = isLow(key, value, isMale, age)
                      const high = isHigh(key, value, isMale, age)

                      if (low || high) {
                        return `
                              <em>
                                  <strong>
                                      <u>${value}</u>
                                  </strong>
                              </em>
                              `
                      } else {
                        return value
                      }
                    });
                  };

                  const replaceLowRange = (text, data, isMale, age) => {
                    if (!data) return text; // Return original text if data is not available
                    return text.replace(/\|(\w+)\|/g, (_, key) => {
                      const test = testInfo[key]
                      if (!test) {
                        return text
                      }

                      if (isMale) {
                        const range = test.rangeForMale
                        let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                        let ran = {}

                        for (let i = 0; i < rangeClone.length; i++) {
                          let curr = rangeClone[i].ageUpto
                          if (Object.keys(ran).length === 0) {
                            if (curr >= age) {
                              ran = rangeClone[i];
                            }
                          }
                        }

                        return ran?.low ?? ""
                      } else {
                        const range = test.rangeForFemale
                        let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                        let ran = {}

                        for (let i = 0; i < rangeClone.length; i++) {
                          let curr = rangeClone[i].ageUpto
                          if (Object.keys(ran).length === 0) {
                            if (curr >= age) {
                              ran = rangeClone[i];
                            }
                          }
                        }

                        return ran?.low ?? ""
                      }
                    });
                  }

                  const replaceHighRange = (text, data, isMale, age) => {
                    if (!data) return text; // Return original text if data is not available
                    return text.replace(/\$(\w+)\$/g, (_, key) => {
                      const test = testInfo[key]
                      if (!test) {
                        return text
                      }

                      if (isMale) {
                        const range = test.rangeForMale
                        let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                        let ran = {}

                        for (let i = 0; i < rangeClone.length; i++) {
                          let curr = rangeClone[i].ageUpto
                          if (Object.keys(ran).length === 0) {
                            if (curr >= age) {
                              ran = rangeClone[i];
                            }
                          }
                        }

                        return ran?.high ?? ""
                      } else {
                        const range = test.rangeForFemale
                        let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                        let ran = {}

                        for (let i = 0; i < rangeClone.length; i++) {
                          let curr = rangeClone[i].ageUpto
                          if (Object.keys(ran).length === 0) {
                            if (curr >= age) {
                              ran = rangeClone[i];
                            }
                          }
                        }

                        return ran?.high ?? ""
                      }
                    });
                  }

                  let layout = replaceResult(data.report.layout, macro.testResult)
                  layout = replaceLowRange(layout, macro.testResult, isMale, age)
                  layout = replaceHighRange(layout, macro.testResult, isMale, age)



                  setTest((prev) => {
                    return { ...prev, [id]: { layout } }
                  })
                }
              }
            }

            setTest((prev) => {

              const test = Object.keys(prev).map((id) => {
                return prev[id]?.layout
              })
              setValue(test.join(""))
              return prev
            })
          }
        }

      } catch (err) {
        console.log(err)
      }
    }

    if (report?.reportFormat.length > 0) {
      fetchTest()
    }
  }, [report?._id, macro])

  const editorConfig = useMemo(() => {
    return {
      readonly: false,
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      //defaultActionOnPaste: "insert_clear_html",
      buttons: buttons,
      uploader: {
        insertImageAsBase64URI: false,
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
        withCredentials: false,
        format: 'json',
        method: 'POST',
        url: 'https://backend.nagalikardiagnostic.com/api/upload-file',
        headers: {
          "authtoken": token
        },
        prepareData: function (data) {
          return data;
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMsg: function (resp) {
          return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
        },
        process: function (resp) {
          return {
            files: [resp[0].src],
            path: '',
            baseurl: '',
            error: resp.error ? 1 : 0,
            msg: resp.msg
          };
        },
        defaultHandlerSuccess: function (data, resp) {
          const files = data.files || [];
          if (files.length) {
            this.selection.insertImage(files[0], null, 250);
          }
        },
        defaultHandlerError: function (resp) {
          this.events.fire('errorPopap', this.i18n(resp.msg));
        }
      },
      width: '100%',
      height: 842
    };
  }, [])

  const handleProcedureContentChange = (content) => {
    setValue(content)
  };

  function htmlToDelta(html) {
    return html
  }

  const saveLayout = async () => {
    try {
      const { data, status } = await api.updateLayout({
        investigationId: layoutId,
        patient: id,
        layout: value
      })

      if (status === 200) {
        toast.success("Saved Successfully")
        dispatch(setValues({ name: "reFetch", value: !reFetch }))
        handleClose()
      }
    } catch (err) {
      toast.error("Error Saving!")
    }
  }

  const insertMacro = (macro) => {
    setValue((value) => value + macro)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="overflow-y-scroll px-0"
    >
      <Box sx={style} >
        <div >
          <header className="flex justify-between  px-6 py-6 bg-[#FAFAFA]">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={macroclasses.name}
            >
              Report Layout
            </Typography>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </header>
          {
            macroAdd &&
            <GetMacro setTest={setMacroAdd} insertMacro={insertMacro} />
          }
          <button onClick={() => setMacroAdd(true)} style={{ background: "#B82C3A", color: "#fff", padding: 5, borderRadius: 2, marginLeft: 10 }}>Add macro</button>
          <div style={{ display: "grid", border: 'none !important', }} className="reportLayout">
            <JoditEditor
              ref={macros}
              value={value}
              config={editorConfig}
              onChange={value => handleProcedureContentChange(value)}
            />
          </div>
          <div
            style={{
              marginTop: '70px',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'right',
              marginRight: '4%',
              marginBottom: "20px"
            }}>
            <Buttons className={classes.cancelButton}
              onClick={handleClose}
            >
              Cancel
            </Buttons>
            <Buttons
              className={classes.submitButton}
              onClick={saveLayout}
            >
              Save
            </Buttons>
          </div>
        </div>
      </Box>
    </Modal>
  );

}

export default ReportLayout;