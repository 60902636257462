import React, { useEffect, useMemo, useRef, useState } from "react"
import 'quill/dist/quill.snow.css'
import ReactQuill, { Quill } from "react-quill";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import { macroStyles } from "../../Styles/Macro";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "./Buttons";
import { toast } from "react-toastify";
import Api from "../../helper/api";
import { formatTime, formatedDate, formatedDateInDigit } from "./../../helper/helper";
import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';
import QuillBetterTable, { quillBetterTable } from "quill-better-table";
import CustomImage from "./CustomImage";
import { useDispatch, useSelector } from "react-redux";
import { setValues } from "../../redux/findPatientSlice";
import "jodit";
import JoditEditor, { Jodit } from "jodit-react";
import AddTest from "./AddTest";

const copyStringToClipboard = function (str) {
    var el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
};

const facilityMergeFields = [
    "FacilityNumber",
    "FacilityName",
    "Address",
    "MapCategory",
    "Latitude",
    "Longitude",
    "ReceivingPlant",
    "TrunkLine",
    "SiteElevation"
];
const inspectionMergeFields = [
    "InspectionCompleteDate",
    "InspectionEventType"
];

const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
    let optionGroupElement = document.createElement("optgroup");
    optionGroupElement.setAttribute("label", optionGrouplabel);
    for (let index = 0; index < mergeFields.length; index++) {
        let optionElement = document.createElement("option");
        optionElement.setAttribute("class", "merge-field-select-option");
        optionElement.setAttribute("value", mergeFields[index]);
        optionElement.text = mergeFields[index];
        optionGroupElement.appendChild(optionElement);
    }
    return optionGroupElement;
}

const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "superscript",
    "subscript",
    "|",
    "align",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "link",
    "table",
    "|",
    "hr",
    "eraser",
    "copyformat",
    "|",
    "fullsize",
    "selectall",
    "print",
    "|",
    "source",
    "|",
    {
        name: "insertMergeField",
        tooltip: "Insert Merge Field",
        iconURL: "images/merge.png",
        popup: (editor, current, self, close) => {
            function onSelected(e) {
                let mergeField = e.target.value;
                if (mergeField) {
                    console.log(mergeField);
                    editor.selection.insertNode(
                        editor.create.inside.fromHTML("{{" + mergeField + "}}")
                    );
                }
            }
            let divElement = editor.create.div("merge-field-popup");

            let labelElement = document.createElement("label");
            labelElement.setAttribute("class", "merge-field-label");
            labelElement.text = 'Merge field: ';
            divElement.appendChild(labelElement);

            let selectElement = document.createElement("select");
            selectElement.setAttribute("class", "merge-field-select");
            selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
            selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
            selectElement.onchange = onSelected;
            divElement.appendChild(selectElement);

            console.log(divElement);
            return divElement;
        }
    },
    {
        name: "copyContent",
        tooltip: "Copy HTML to Clipboard",
        iconURL: "images/copy.png",
        exec: function (editor) {
            let html = editor.value;
            copyStringToClipboard(html);
        }
    }
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    height: "100%",
    width: "800.88px",
    padding: "0 0px",
    overflow: "scroll",
    display: "block",

    borderRadius: "6px",
    "&:focus": {
        outline: "none",
    },
};

const MasterMacro = ({ handleClose, data, refresh }) => {
    const api = new Api();
    const classes = formStyles();
    const macroclasses = macroStyles();

    const [open, setOpen] = React.useState(true);
    const macros = useRef(null)
    const [value, setValue] = useState(null);
    const token = localStorage.getItem("logintoken")

    const editorConfig = useMemo(() => {
        return {
            readonly: false,
            toolbar: true,
            spellcheck: true,
            language: "en",
            toolbarButtonSize: "medium",
            toolbarAdaptive: false,
            showCharsCounter: true,
            showWordsCounter: true,
            showXPathInStatusbar: false,
            askBeforePasteHTML: true,
            askBeforePasteFromWord: true,
            //defaultActionOnPaste: "insert_clear_html",
            buttons: buttons,
            uploader: {
                insertImageAsBase64URI: false,
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                withCredentials: false,
                format: 'json',
                method: 'POST',
                url: 'https://backend.nagalikardiagnostic.com/api/upload-file',
                headers: {
                    "authtoken": token
                },
                prepareData: function (data) {
                    return data;
                },
                isSuccess: function (resp) {
                    return !resp.error;
                },
                getMsg: function (resp) {
                    return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
                },
                process: function (resp) {
                    return {
                        files: [resp[0].src],
                        path: '',
                        baseurl: '',
                        error: resp.error ? 1 : 0,
                        msg: resp.msg
                    };
                },
                defaultHandlerSuccess: function (data, resp) {
                    const files = data.files || [];
                    if (files.length) {
                        this.selection.insertImage(files[0], null, 250);
                    }
                },
                defaultHandlerError: function (resp) {
                    this.events.fire('errorPopap', this.i18n(resp.msg));
                }
            },
            width: '100%',
            height: 842
        };
    }, [])

    useEffect(() => {
        if (data?.macro) {
            setValue(data.macro)
        }
    }, [data._id]);

    const handleProcedureContentChange = (content) => {
        setValue(content)
    };

    const saveMacro = async () => {
        try {
            const { status } = await api.macro().put(`/macro/${data._id}`,
                {
                    macro: value
                }
            )

            if (status === 200) {
                toast.success("Saved Successfully")
                refresh()
                handleClose()
            }
        } catch (err) {
            console.log("err-----", err)
            toast.error("Error Saving!")
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="overflow-y-scroll px-0"
        >
            <Box sx={style} >
                <div >
                    <header className="flex justify-between  px-6 py-6 bg-[#FAFAFA]">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className={macroclasses.name}
                        >
                            Macro - {data?.code}
                        </Typography>
                        <button onClick={handleClose}>
                            <CloseIcon />
                        </button>
                    </header>
                    <div style={{ display: "grid", border: 'none !important', }} className="jodit-editor" >
                        <JoditEditor
                            ref={macros}
                            value={value}
                            config={editorConfig}
                            onChange={value => handleProcedureContentChange(value)}
                        />
                    </div>
                    <div
                        style={{
                            marginTop: '70px',
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'right',
                            marginRight: '4%',
                            marginBottom: "20px"
                        }}>
                        <Buttons className={classes.cancelButton}
                            onClick={handleClose}
                        >
                            Cancel
                        </Buttons>
                        <Buttons
                            className={classes.submitButton}
                            onClick={saveMacro}
                        >
                            Save
                        </Buttons>
                    </div>
                </div>
            </Box>
        </Modal>
    );

}

export default MasterMacro;