import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import Api from "../helper/api";

import CreatableSelect from 'react-select/creatable';

const MaterialTableWithInput = ({ refRange, test, report, patience, params }) => {
  const [value, setValue] = useState({}); // set result here
  const [path, setPath] = useState("");
  const { reportId, id } = useParams();

  const [verif, setVerify] = useState(false);
  const [testResult, setTestResult] = useState([]);

  const api = new Api();
  const gender = patience?.gender;
  const isMale = patience?.gender === "male" ? true : false;
  const age = patience?.age


  const urlValue = () => {
    const path = window.location.pathname;
    if (path.startsWith("/verify-report/")) {
      setPath(path);
      verify(path);
    } else {
      const extractedValue = path.split("/").pop();
      setPath(extractedValue);
    }
  };

  const verify = () => {
    const input = path;
    const substring = "verify-report";

    if (input?.includes(substring)) {
      setVerify(true);
    } else {
      setVerify(false);
    }
  };

  useEffect(() => {
    if (patience?.testResult?.length > 0) {
      const data = patience?.testResult.find((test) => test.investigationId === parseInt(params.reportId))

      setTestResult(data?.testResult)
    }
  }, [patience?.testResult]);


  useEffect(() => {
    if (testResult?.length > 0) {

      let data = {}
      for (let i = 0; i < testResult.length; i++) {

        let id = testResult[i].testId;
        data = {
          ...data, [id]: {
            result: testResult[i].result,
            testId: testResult[i].testId
          }
        }
      }

      setValue(data)
    }
  }, [testResult])

  useEffect(() => {
    urlValue();
    verify();
  }, [path]);


  const navigate = useNavigate();

  const tableStyles = makeStyles({
    table: {
      borderTop: "1px solid rgba(96, 96, 96, 0.3)",
    },
    tableCell: {
      height: 15,
      borderRight: "1px solid rgba(96, 96, 96, 0.3)",

      padding: "8px",
    },
    container: {
      overflow: "hidden",
    },
  });

  const classes = tableStyles();

  const handleResultChange = (e, id) => {
    // e.persist();

    setValue((prev) => {
      return {
        ...prev, [id]: {
          result: e?.value,
          testId: id
        }
      }
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (verif) {
      try {
        const { data, status } = await api.verifyPatientReport({
          patient: patience._id,
          investigationId: report._id,
          testResult: Object.keys(value).map((id) => {
            return value[id]
          })
        })

        if (status === 200) {
          toast.success("Report Verified");
          navigate("/list-patient");
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        const { data, status } = await api.updatePatientReport({
          patient: patience._id,
          investigationId: report._id,
          testResult: Object.keys(value).map((id) => {
            return value[id]
          })
        })

        if (status === 200) {
          toast.success("Report Entered");
          navigate("/list-patient");
        }
      } catch (e) {
        console.error(e);
      }
    }


  };
  return (
    <form onSubmit={handleSubmit}>
      <TableContainer
        component={Paper}
        style={{ marginTop: "20px" }}
        className={classes.container}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                Test Parameter
              </TableCell>
              <TableCell className={classes.tableCell}>Result</TableCell>
              <TableCell className={classes.tableCell}>Low</TableCell>
              <TableCell className={classes.tableCell}>High</TableCell>
              <TableCell className={classes.tableCell}>
                Reference Range
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              test?.map((item, index) => {
                const arr = item.options.map((item) => {
                  return {
                    value: item.option,
                    label: item.option
                  }
                })

                const low = () => {
                  if (isMale) {
                    const range = item.rangeForMale
                    let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                    let ran = {}

                    for (let i = 0; i < rangeClone.length; i++) {
                      let curr = rangeClone[i].ageUpto
                      if (Object.keys(ran).length === 0) {
                        if (curr >= age) {
                          ran = rangeClone[i];
                        }
                      }
                    }

                    return ran?.low ?? ""
                  } else {
                    const range = item.rangeForFemale
                    let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                    let ran = {}

                    for (let i = 0; i < rangeClone.length; i++) {
                      let curr = rangeClone[i].ageUpto
                      if (Object.keys(ran).length === 0) {
                        if (curr >= age) {
                          ran = rangeClone[i];
                        }
                      }
                    }

                    return ran?.low ?? ""
                  }
                }

                const high = () => {
                  if (isMale) {
                    const range = item.rangeForMale
                    let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                    let ran = {}

                    for (let i = 0; i < rangeClone.length; i++) {
                      let curr = rangeClone[i].ageUpto
                      if (Object.keys(ran).length === 0) {
                        if (curr >= age) {
                          ran = rangeClone[i];
                        }
                      }
                    }

                    return ran?.high ?? ""
                  } else {
                    const range = item.rangeForFemale
                    let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                    let ran = {}

                    for (let i = 0; i < rangeClone.length; i++) {
                      let curr = rangeClone[i].ageUpto
                      if (Object.keys(ran).length === 0) {
                        if (curr >= age) {
                          ran = rangeClone[i];
                        }
                      }
                    }

                    return ran?.high ?? ""
                  }
                }
                return (
                  <>
                    <TableRow key={item?._id}>
                      <TableCell className={classes.tableCell}>
                        {item?.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <CreatableSelect
                          styles={{ menu: (styles) => ({}) }}
                          isDisabled={report?.status == "Verified" && !verif || report?.status.toUpperCase() == "PRINTED" && !verif}
                          onChange={(e) => handleResultChange(e, item._id)} placeholder="Enter" value={{ value: value[item._id]?.result || "", label: value[item._id]?.result || "" }} options={arr} />
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                      >
                        <span>{low()}</span>
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                      >
                        <span>{high()}</span>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                      >
                        {item?.rangeForMale?.map((ran) => {
                          return <span>{ran?.refRange}</span>
                        })}
                      </TableCell>

                    </TableRow>
                  </>
                )
              })
            }

          </TableBody>
        </Table>
      </TableContainer>
      <Button
        disabled={!value || report?.status == "Verified" && !verif || report?.status.toUpperCase() == "PRINTED" && !verif}
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
      >
        {verif ? "Verify" : "Enter"}
      </Button>
    </form>
  );
};

export default MaterialTableWithInput;
